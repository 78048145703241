<template>
  <div>
    <h1 align="center">Profile -{{ createdprofile.candidateName }}</h1>
    <v-container>
      <v-snackbar v-model="snackbar">
        {{ snackbartext }}
        <template v-slot:action="{ attrs }">
          <v-btn color="success" text v-bind="attrs" @click="window.close()"> Okay </v-btn>
        </template>
      </v-snackbar>
      <v-form>
        <v-expansion-panels focusable multiple popout :value="expanded">
          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text">
              <h2>Profile Details</h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm4 md1 lg1 pl-1>
                  <div>
                    <h4>JOB ID</h4>
                    {{ createdprofile.jobId }}
                  </div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Title</h4>
                  <div>{{ createdprofile.title }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Name</h4>
                  <div>{{ createdprofile.candidateName }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg2 pl-2>
                  <h4>Notice Period</h4>
                  <div>{{ createdprofile.noticePeriod }}</div>
                </v-flex>
                <v-flex xs6 sm4 md3 lg2 pl-2>
                  <h4>Total Experence(yrs)</h4>
                  <div>{{ createdprofile.totalExperience }}</div>
                </v-flex>
                <v-flex xs6 sm4 md4 lg3 pl-2>
                  <h4>Relavent Experence(Yrs)</h4>
                  <div>{{ createdprofile.relaventExperience }}</div>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text">
              <h2>Scores Details</h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm4 md2 lg2 pl-2>
                  <div>
                    <h4>Manager Score</h4>
                    {{ createdprofile.scores.manager }}
                  </div>
                </v-flex>
                <v-flex v-if="createdprofile.scores" xs6 sm4 md2 lg2 pl-2>
                  <div v-if="client1">
                    <h4>Client1 Score-{{ createdprofile.scores.client1 }}</h4>
                    <v-card>
                      <v-card-text>
                        <v-slider
                          v-model="createdprofile.scores.client1"
                          step="1"
                          min="0"
                          max="10"
                          ticks
                          thumb-label
                          hide-details
                          @change="calculatescores"
                        ></v-slider>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div v-else>
                    <h4>Client1 Score</h4>
                    {{ createdprofile.scores.client1 }}
                  </div>
                </v-flex>
                <v-flex v-if="!client1" xs6 sm4 md2 lg2 pl-2>
                  <h4>Client2 Score-{{ createdprofile.scores.client2 }}</h4>
                  <v-card>
                    <v-card-text>
                      <v-slider
                        v-model="createdprofile.scores.client2"
                        step="1"
                        min="0"
                        max="10"
                        ticks
                        thumb-label
                        hide-details
                        @change="calculatescores"
                      ></v-slider>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs6 sm4 md2 lg2 pl-2>
                  <div>
                    <h4>Total Score</h4>
                    {{ createdprofile.scores.total }}
                  </div>
                </v-flex>

                <v-flex xs6 sm4 md2 lg2 pl-2>
                  <div>
                    <h4>Average Score</h4>
                    {{ createdprofile.scores.average }}
                  </div>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text">
              <h2>Comments Details</h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs12 sm12 md6 lg6 pl-2>
                  <div><h4>Manager Comments</h4></div>
                  <div v-html="createdprofile.manangercomments"></div>
                </v-flex>
                <v-flex xs12 sm12 md6 lg6 pl-1>
                  <div><h4>Client Comments</h4></div>
                  <tiptap-vuetify
                    v-if="client1"
                    max-height="250px"
                    v-model="createdprofile.clientcomments"
                    :extensions="extensions"
                  />
                  <div v-else v-html="createdprofile.clientcomments"></div>
                </v-flex>
                <v-flex v-if="!client1" xs12 sm12 md6 lg6 pl-1>
                  <div><h4>Client Comments2</h4></div>
                  <tiptap-vuetify
                    max-height="250px"
                    v-model="createdprofile.clientcomments2"
                    :extensions="extensions"
                  />
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text"> <h2>Documents</h2></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row wrap>
                <Attachment
                  v-for="(file, index) in createdprofile.resume"
                  :key="file.filename"
                  :file="file"
                  :index="index"
                  :close="false"
                  :color="'green'"
                />
                <Attachment
                  v-for="(file, index) in createdprofile.testreport"
                  :key="file.filename"
                  :file="file"
                  :index="index"
                  :close="false"
                  :color="'green'"
                />
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn text class="savecolor ml-4 mt-1" @click="saveitem"> Save </v-btn>
        <v-btn text class="cancelcolor ml-2 mt-1" @click="close"> Cancel </v-btn>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { recruitmentRef, clientprofilesRef } from "@/fb"
/*import { formMixin } from "../../mixins/formMixin.js"
import { emailMixin } from "../../mixins/emailMixin.js"
import { convertDates } from "../../mixins/convertDates.js"*/
import Attachment from "@/components/common/Attachment.vue"

import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  History,
} from "tiptap-vuetify"

export default {
  name: "clientprofile",
  //props:['roles'],
  //mixins: [formMixin,emailMixin,convertDates],
  components: { TiptapVuetify, Attachment },
  data: () => ({
    expanded: [0, 1, 2, 3],
    profileid: "",
    createdprofile: "",

    extensions: [
      History,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
      Paragraph,
      HardBreak,
    ],
    snackbar: false,
    alertflag: false,
    snackbartext: "",
    alertmsg: "",

    recordfound: false,
    recorduid: "",
    client1: true,

    // profile:[],
  }),
  async created() {
    this.profileid = this.$route.params.id
    console.log("inside client profile created", this.profileid)
    await this.initialize()
  },
  methods: {
    calculatescores() {
      console.log("insidecalculatescores", this.createdprofile.scores)
      var denominator = 3
      this.createdprofile.scores.total =
        this.createdprofile.scores.manager + this.createdprofile.scores.client1 + this.createdprofile.scores.client2

      if (this.createdprofile.scores.manager == 0) denominator--

      if (this.createdprofile.scores.client1 == 0) denominator--

      if (this.createdprofile.scores.client2 == 0) denominator--

      if (denominator != 0 && this.createdprofile.scores.total != 0) {
        var tmpaverage = this.createdprofile.scores.total / denominator

        this.createdprofile.scores.average = tmpaverage.toFixed(2)
      } else this.createdprofile.scores.average = 0
      console.log("after calculatescores", this.createdprofile.scores)
    },
    close() {
      window.close()
    },

    async saveitem() {
      console.log("isnide save item", this.recordfound)
      try {
        console.log(
          "add/update item",
          this.profileid,
          this.createdprofile.scores,
          this.createdprofile.clientcomments,
          this.createdprofile.clientcomments2
        )
        if (this.recordfound) {
          await clientprofilesRef.doc(this.recorduid).set({
            uid: this.profileid,
            scores: this.createdprofile.scores,
            clientcomments: this.createdprofile.clientcomments,
            clientcomments2: this.createdprofile.clientcomments2,
          })
        } else {
          await clientprofilesRef.add({
            uid: this.profileid,
            scores: this.createdprofile.scores,
            clientcomments: this.createdprofile.clientcomments,
            clientcomments2: this.createdprofile.clientcomments2,
          })
        }
        this.snackbar = true
        this.snackbartext = "Profile updated successfully :Thankyou"
      } catch (error) {
        this.alertflag = true
        this.alertmsg = "Errro while update" + error
      }
    },

    async initialize() {
      var initalscores = { manager: 0, client1: 0, client2: 0, total: 0, average: 0 }
      var queryresult = await clientprofilesRef.where("uid", "==", this.profileid).get()
      console.log("record length", queryresult.docs.length)
      if (queryresult.docs.length == 1) {
        this.recordfound = true
        this.recorduid = queryresult.docs[0].id
      } else {
        this.recordfound = false
      }
      await recruitmentRef
        .doc(this.profileid)
        .get()
        .then((docRef) => {
          this.createdprofile = JSON.parse(JSON.stringify(docRef.data()))
          var tmpscores = this.createdprofile.scores
          if (!tmpscores) {
            console.log("assigning intial score")
            this.createdprofile.scores = Object.assign({}, initalscores)
            // this.createdprofile.scores=JSON.parse(initalscores);
            //JSON.parse(JSON.stringify( initalscores));
            //Object.assign({},{manager:0,client1:0,client2:0,total:0,average:0})
          }
          if (this.recordfound) {
            this.createdprofile.scores = queryresult.docs[0].data().scores
            var tmpcomments = queryresult.docs[0].data().clientcomments
            console.log("tmpcomments1", tmpcomments)
            if (tmpcomments) this.createdprofile.clientcomments = tmpcomments
            else this.createdprofile.clientcomments = null

            tmpcomments = queryresult.docs[0].data().clientcomments2
            console.log("tmpcomments2", tmpcomments)
            if (tmpcomments) this.createdprofile.clientcomments2 = tmpcomments
            else this.createdprofile.clientcomments2 = null
          }
          if (this.createdprofile.scores.client1 == 0) this.client1 = true
          else this.client1 = false

          console.log("isnide clientprofile", this.createdprofile)
        })
        .catch((error) => {
          console.log("Error in initialize", error)
        })
    }, // end of initialize
  }, //end of methods
}
</script>
